<template>
    <div  class="like-btn-container">
      <div class="circle-bg"></div>
        <svg 
           width="48" height="48" viewBox="0 0 48 48" fill="none" 
           xmlns="http://www.w3.org/2000/svg"
           id="Component_9_1"
          :class="'Component_9_1' + content.objectid"
           data-name="Component 9 – 1"
           @click="toggleDetailShare(true)"
        >
            <g filter="url(#filter0_b_792_2504)">
                <circle cx="24" cy="24" r="24" fill="white" fill-opacity="0.3" />
            </g>
            <path
                d="M29.4492 27.4745C28.8082 27.4745 28.2347 27.7275 27.7962 28.1239L21.7829 24.6239C21.8251 24.4299 21.8588 24.236 21.8588 24.0336C21.8588 23.8311 21.8251 23.6372 21.7829 23.4432L27.7287 19.9769C28.1841 20.3986 28.7829 20.6601 29.4492 20.6601C30.8492 20.6601 31.9793 19.5299 31.9793 18.1299C31.9793 16.7299 30.8492 15.5998 29.4492 15.5998C28.0492 15.5998 26.9191 16.7299 26.9191 18.1299C26.9191 18.3324 26.9528 18.5263 26.995 18.7203L21.0492 22.1866C20.5938 21.7649 19.995 21.5034 19.3287 21.5034C17.9287 21.5034 16.7986 22.6336 16.7986 24.0336C16.7986 25.4336 17.9287 26.5637 19.3287 26.5637C19.995 26.5637 20.5938 26.3022 21.0492 25.8805L27.054 29.389C27.0118 29.5661 26.9865 29.7516 26.9865 29.9372C26.9865 31.295 28.0914 32.3998 29.4492 32.3998C30.807 32.3998 31.9118 31.295 31.9118 29.9372C31.9118 28.5793 30.807 27.4745 29.4492 27.4745Z"
                fill="white" />
            <defs>
                <filter id="filter0_b_792_2504" x="-6" y="-6" width="60" height="60" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="3" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_792_2504" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_792_2504" result="shape" />
                </filter>
            </defs>
        </svg>
        <!-- <svg
          id="Component_9_1"
          :class="'Component_9_1' + content.objectid"
          data-name="Component 9 – 1"
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="27"
          viewBox="0 0 27 27"
         @click="toggleDetailShare(true)"
        >
          <g
            id="Group_92"
            data-name="Group 92"
            transform="translate(-476 404) rotate(180)"
          >
            <g
              id="Path_20"
              data-name="Path 20"
              transform="translate(-503 377)"
             :fill="!toggleContentShare ? '#161616' : '#E81C25'"
            >
              <path
                d="M13.5,0A13.5,13.5,0,1,1,0,13.5,13.5,13.5,0,0,1,13.5,0Z"
                stroke="none"
              />
              <path
                d="M 13.5 1 C 10.16113090515137 1 7.022109985351562 2.300230026245117 4.661169052124023 4.661169052124023 C 2.300230026245117 7.022109985351562 1 10.16113090515137 1 13.5 C 1 16.83887100219727 2.300230026245117 19.97789001464844 4.661169052124023 22.33882904052734 C 7.022109985351562 24.69976997375488 10.16113090515137 26 13.5 26 C 16.83887100219727 26 19.97789001464844 24.69976997375488 22.33882904052734 22.33882904052734 C 24.69976997375488 19.97789001464844 26 16.83887100219727 26 13.5 C 26 10.16113090515137 24.69976997375488 7.022109985351562 22.33882904052734 4.661169052124023 C 19.97789001464844 2.300230026245117 16.83887100219727 1 13.5 1 M 13.5 0 C 20.95584106445312 0 27 6.044160842895508 27 13.5 C 27 20.95584106445312 20.95584106445312 27 13.5 27 C 6.044160842895508 27 0 20.95584106445312 0 13.5 C 0 6.044160842895508 6.044160842895508 0 13.5 0 Z"
                stroke="none"
                fill="#E81C25"
              />
            </g>
            <g
              id="share_black_24dp"
              transform="translate(-481.376 398.624) rotate(180)"
            >
              <path
                id="Path_12"
                data-name="Path 12"
                d="M0,0H16.248V16.248H0Z"
                fill="none"
              />
              <path
                id="Path_13"
                :class="'Path_13' + content.objectid"
                data-name="Path 13"
                d="M13.155,11.532a1.971,1.971,0,0,0-1.327.521L7,9.244a2.216,2.216,0,0,0,.061-.474A2.216,2.216,0,0,0,7,8.3l4.773-2.782a2.026,2.026,0,1,0-.65-1.483,2.216,2.216,0,0,0,.061.474L6.412,7.287a2.031,2.031,0,1,0,0,2.965l4.82,2.816a1.91,1.91,0,0,0-.054.44,1.977,1.977,0,1,0,1.977-1.977Zm0-8.178a.677.677,0,1,1-.677.677A.679.679,0,0,1,13.155,3.354ZM5.031,9.447a.677.677,0,1,1,.677-.677A.679.679,0,0,1,5.031,9.447ZM13.155,14.2a.677.677,0,1,1,.677-.677A.679.679,0,0,1,13.155,14.2Z"
                transform="translate(-0.969 -0.646)"
                fill="#ffffff"
              />
            </g>
          </g>
        </svg> -->
    </div>
</template>
  
<script>
import { eventBus } from "@/eventBus";

export default {
    props: {
        content: {
            type: Object
        },
        width: {
            type: String
        },
        height: {
            type: String
        }
    },
    data() {
        return {
            toggleContentShare: false,
            shareContent: null,
        };
    },
    mounted() {
        try {
            document.getElementsByClassName(`Component_9_1${this.content.objectid}`)[0].addEventListener("mouseover", () => {
                document.getElementsByClassName(`Path_13${this.content.objectid}`)[0].setAttribute("fill", "white")
            });

            document.getElementsByClassName(`Component_9_1${this.content.objectid}`)[0].addEventListener("mouseout", () => {
                document.getElementsByClassName(`Path_13${this.content.objectid}`)[0].setAttribute("fill", "#ffffff")
            })

        } catch (e) {

        }
    },
    methods: {
        toggleDetailShare(state) {
            let payload = {
                popupState: state,
                content: this.content
            };
            eventBus.$emit("show-content-share", payload);
        }
    }
};
</script>
  
<style>

.like-btn-container {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.circle-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color 0.3s;
  z-index: -1;
  pointer-events: none;
}

.like-btn-container:hover .circle-bg {
  background-color: #fff; 
}

.like-btn-container:hover svg path {
  fill: #000 !important; 
}
</style>